 //add valdation switch to keep formbuilder validation after custom validation
 $('button[name="anmeldung_submit"]').on("click", function() {
	var email1 = document.getElementById("Inputfield_e_mail").value;
	var email2 = document.getElementById("Inputfield_bestaetigung_e_mail_adresse").value;

	if (document.getElementById("Inputfield_customvalidation").value == "" && email1 != "" && email2 != "") {
		event.preventDefault();

		//check email
		if (email1 != email2 && email2 != "") {
			$(".errorMSG").remove();
			var errorMSG = "<div class='errorMSG' style='background-color: rgba(162, 0, 50, 0.1); color: #a20032;font-size: 14px;border: 1px solid #a20032;padding: 15px;'>Die E-Mail-Adressen sind nicht identisch. </div>";
			$("#customErrorBox").append(errorMSG);
			$("#Inputfield_bestaetigung_e_mail_adresse").val("");
		} else { //check if email is already in use
			var form = document.getElementsByTagName("FORM")[0];
			xhttp = new XMLHttpRequest();
			xhttp.onreadystatechange = function() {
				if (this.readyState == 4 && this.status == 200) {
					if (this.responseText === 'belegt') {
						$(".errorMSG").remove();
						var errorMSG = "<div class='errorMSG' style='background-color: rgba(162, 0, 50, 0.1); color: #a20032;font-size: 14px;border: 1px solid #a20032;padding: 15px;'>Die E-Mail-Adresse ist bereits vergeben. </div>";
						$("#customErrorBox").append(errorMSG);
						$("#Inputfield_e_mail").val("");
						$("#Inputfield_bestaetigung_e_mail_adresse").val("");
					} else if (this.responseText === 'frei') {
						//revalidate form if no errors are in custom validation
						$(".errorMSG").remove();
						$("#Inputfield_customvalidation").val("1");
						$('button[name="anmeldung_submit"]').click();
					}
				}
			};
			xhttp.open("GET", "/validierung/?email=" + email1, true);
			xhttp.send();
		}

	} else {
		$("#Inputfield_customvalidation").val("");
	}
});

//js for hiding fields
$("#wrap_anmeldung_submit").before("<p class='form-meta'>Die mit * gekennzeichneten Felder sind Pflichtfelder.</p>");